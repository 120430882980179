import React, { FC } from 'react';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';
import UnityIcon from '../assets/skill-icons/Unity.png';
import DotNetIcon from '../assets/skill-icons/DotNet.png';
import ReactIcon from '../assets/skill-icons/React.png';
import WordPressIcon from '../assets/skill-icons/WordPress.png';
import PizzaIcon from '../assets/skill-icons/Pizza.png';
import AnimateIcon from '../assets/skill-icons/Animate.png';

const skills = [
    {
        title: ".NET Core",
        icon: DotNetIcon,
    },
    {
        title: "React",
        icon: ReactIcon,
    },
    {
        title: "Unity",
        icon: UnityIcon,
    },
    {
        title: "WordPress",
        icon: WordPressIcon,
    },
    {
        title: "Making pizza",
        icon: PizzaIcon,
    },
];

const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        width: 128,
        height: 128,
        marginBottom: theme.spacing(2)
    },
    title: {
        fontWeight: "bold"
    }
}));

export const MySkills: FC = () => {

    const classes = useStyles();

    return (
        <Grid container spacing={4} justify="center">
            {skills.map((skill, index) => (
                <Grid item>
                    <div>
                        <Avatar className={classes.avatar} src={skill.icon} />
                    </div>
                    <div>
                        <Typography className={classes.title} align="center">
                            {skill.title}
                        </Typography>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
}
