import React, { FC } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import SchoolIcon from '@material-ui/icons/School';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, CardHeader, Divider, Hidden, makeStyles, Theme } from '@material-ui/core';
import { TimelineOppositeContent } from '@material-ui/lab';

const timelineEvents = [
    {
        title: "Roskilde Technical School",
        about: "Studied to become a 3D animator, but graduated in Web Development.",
        details: "2008",
        icon: <SchoolIcon />,
    },
    {
        title: "Arkuni Games",
        about: "Game development company founded by me to create juicy action games in Flash.",
        details: "2010",
        icon: <LaptopMacIcon />,
    },
    {
        title: "IT University of Copenhagen",
        about: "Graduated with a bachelor in Software Development.",
        details: "2012",
        icon: <SchoolIcon />,
    },
    {
        title: "Freelance Web Developer",
        about: "Consulted and developed websites using ASP.NET and Wordpress for companies like AnnoAnno and UBVA.",
        details: "2015",
        icon: <LaptopMacIcon />,
    },
    {
        title: "Bitcoin Suisse",
        about: "Worked back-end and front-end tasks on all the customer-facing platforms, like a Payment Gateway, Online Portfolio and Client Management.",
        details: "2017",
        icon: <LaptopMacIcon />,
    },
    {
        title: "Team Wood Games",
        about: "Another game development company founded by me and a buddy to create casual multiplayer games.",
        details: "2019",
        icon: <LaptopMacIcon />,
    },
];

const useStyles = makeStyles((theme: Theme) =>
({
    timelineRoot: {
        padding: 0
    },
    card: {
        backgroundColor: theme.palette.background.paper
    },
    oppositeContent: {
        flex: "0",
        padding: 0,
    },
}));

export const MyTimeline: FC = () => {

    const classes = useStyles();

    return (
        <Timeline className={classes.timelineRoot} align="left">
            {timelineEvents.map((timelineEvent, index) => (
                <TimelineItem>
                        <TimelineOppositeContent classes={{ root: classes.oppositeContent }} />
                    <TimelineSeparator>
                            <TimelineDot color="primary" variant="outlined">
                                {timelineEvent.icon}
                            </TimelineDot>
                            {timelineEvent.details}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Card>
                            <CardHeader
                                title={timelineEvent.title}
                                subheader={timelineEvent.about}
                            />
                        </Card>
                    </TimelineContent>
                </TimelineItem>
            ))}
                <TimelineItem>
                <TimelineOppositeContent classes={{ root: classes.oppositeContent }} />
                    <TimelineSeparator>
                        <TimelineDot color="primary" variant="outlined">
                            <LaptopMacIcon />
                        </TimelineDot>
                            Today
                    </TimelineSeparator>
                    <TimelineContent />
                </TimelineItem>
        </Timeline>
    );
}

