import React, { FC } from 'react';
import { Route, Switch } from "react-router-dom";
import { Portfolio } from '.';

export const Routes: FC = (props) =>
{

    return (
        <Switch>
            <Route path="/" exact>
                <Portfolio />
            </Route>
        </Switch>
    );
}
