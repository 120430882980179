import { Typography, Box } from '@material-ui/core';
import React, { FC } from 'react';
import { MySkills, MyTimeline, MyProjects } from '.';


export const Portfolio: FC = (props) => {
    return (
        <>
            <Box mb={4} />
            <Typography variant="h4" align="center">
                Hello there!
            </Typography>
            <Typography variant="h6" align="center">
                I make games, software and pizza 🍕
            </Typography>
            <Box mb={8} />
            <MyProjects />
            
            
            <Box mb={8} />
            <Typography variant="h4" align="center">
                This is how I have spent my time ⌚
            </Typography>
            <MyTimeline />
            
            <Box mb={8} />
            <Typography variant="h4" align="center">
                These are my skills ⭐
            </Typography>
            <br/>
            <MySkills />
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </>
    );
}
