import { Box, Button, Grid, Hidden, IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Link } from "react-router-dom";

export const Header = () => 
{
    return (
        <>
            <Toolbar />
            <AppBar position="fixed">
                <Toolbar>
                    <Box display="flex">
                        <Link to="/">
                            <Typography variant="h6" noWrap>
                                Arkuni
                            </Typography>
                        </Link>
                    </Box>
                    <Box flexGrow={1} />
                    <IconButton component="a" href="https://twitter.com/ArkuniOfficial" color="inherit">
                        <TwitterIcon />
                    </IconButton>
                    <IconButton component="a" href="mailto:contact@arkuni.com" color="inherit">
                        <EmailIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
    );
}