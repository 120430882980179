import { Card, CardHeader, Box, CardActionArea, Avatar, makeStyles } from '@material-ui/core';
import React, { FC, Fragment } from 'react';
import GangOfPawsIcon from '../assets/project-icons/GangOfPaws.png';
import SuperAutoPetsIcon from '../assets/project-icons/SuperAutoPets.png';
import BitBlobIcon from '../assets/project-icons/BitBlob.png';
import VersusUmbraIcon from '../assets/project-icons/VersusUmbra.png';
import TheFinalDeathWishIcon from '../assets/project-icons/TheFinalDeathWish.png';
import AnotherCaveRunnerIcon from '../assets/project-icons/AnotherCaveRunner.png';
import AttackOfTheUbermothersIcon from '../assets/project-icons/AttackOfTheUbermothers.png';
import { Theme } from '@material-ui/core';

const projects = [
    {
        title: "Gang of Paws",
        about: "My love letter to Mega Man Battle Network.",
        icon: GangOfPawsIcon,
        href: "https://gopaws.arkuni.com/",
    },
    {
        title: "Super Auto Pets",
        about: "Build a team of fierce pets and compete with other players online.",
        icon: SuperAutoPetsIcon,
        href: "https://teamwoodgames.com/",
    },
    {
        title: "Bit Blob",
        about: "Free Pixel icon pack to use in game development.",
        icon: BitBlobIcon,
        href: "https://bit-blob.arkuni.com/",
    },
    {
        title: "Versus Umbra",
        about: "Explore an action packed galaxy in the spiritual sequel to The Final Death Wish.",
        icon: VersusUmbraIcon,
        href: "https://www.newgrounds.com/portal/view/586318",
    },
    {
        title: "The Final Death Wish",
        about: "Retrieve the magic brush tool in this explosive 2D arena shooter.",
        icon: TheFinalDeathWishIcon,
        href: "https://www.newgrounds.com/portal/view/536018",
    },
    {
        title: "Another Cave Runner",
        about: "Run, run and keep on running in another infinite runner.",
        icon: AnotherCaveRunnerIcon,
        href: "https://www.newgrounds.com/portal/view/564281",
    },
    {
        title: "Attack of the Ubermothers",
        about: "Survive the relentless onslaught of the always-pregnant Ubermothers.",
        icon: AttackOfTheUbermothersIcon,
        href: "https://www.newgrounds.com/portal/view/534659",
    },
];

const useStyles = makeStyles((theme: Theme) =>
({
    avatar: {
        width: 80,
        height: 80,
        borderStyle: "solid",
        borderWidth: 4,
        borderColor: theme.palette.background.default
    },
    title: {
        fontSize: "1.5rem"
    }
}));

export const MyProjects: FC = (props) => {
    const classes = useStyles();

    return (
        <>
            {projects.map((project) => (
                <Fragment key={project.title}>
                    <Card>
                        <CardActionArea component="a" href={project.href}>
                            <CardHeader
                                title={project.title}
                                subheader={project.about}
                                classes={{
                                    title: classes.title
                                }}

                                avatar={
                                    <Avatar className={classes.avatar} src={project.icon} />
                                }
                            />
                        </CardActionArea>
                    </Card>
                    <Box mb={1} />
                </Fragment>
            ))}
        </>
    );
}

