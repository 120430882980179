import { createMuiTheme, darken } from "@material-ui/core";
import indigo from '@material-ui/core/colors/indigo';
import orange from '@material-ui/core/colors/orange';

const newTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: indigo,
        text: {
            
        },
        background: {
            default: darken(indigo[900], 0.5),
            paper: indigo[500]
        }
    },

});

export const theme = newTheme;