import { ThemeProvider } from '@material-ui/core/styles';
import { Layout, Routes } from 'components';
import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { theme } from 'style';

function App() 
{
	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Layout>
					<Routes />
				</Layout>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
