import { Header } from 'components';
import React, { FC } from 'react';
import { Box, Container, CssBaseline } from '@material-ui/core';

export const Layout: FC = (props) =>
{
    return (
        <>
            <CssBaseline />
            <Header />
            <Container maxWidth="md">
                <div>
                    {props.children}
                </div>
            </Container>
        </>
    );
}
